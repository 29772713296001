import { createContext, useContext, useEffect, useMemo, useState } from "react";

export interface PlayerData {
    isPlaying: boolean;
    trackUrl: string | undefined;
}

interface MusicContextPlaying {
    setIsPlaying: (isPlaying: boolean) => void;
    setTrackUrl: (trackUrl: string | undefined) => void;
    player: PlayerData;
}

export const MusicContext = createContext<MusicContextPlaying>({
    setIsPlaying: () => {},
    setTrackUrl: () => {},
    player: {
        isPlaying: true,
        trackUrl: undefined,
    }
});

export function useMusicPlayer(): MusicContextPlaying {
    return useContext(MusicContext);
}

export const MusicGlobal: React.FC = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(true);
    const [trackUrl, setTrackUrl] = useState<string | undefined>(undefined);
    const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);

    const ctx = useMemo<MusicContextPlaying>(() => {
        return {
            setIsPlaying: (isPlaying: boolean) => { setIsPlaying(isPlaying) },
            setTrackUrl: (trackUrl: string | undefined) => {
                console.log("set track url", trackUrl);
                setTrackUrl(trackUrl)
            },
            player: {
                isPlaying,
                trackUrl,
            }
        }
    }, [isPlaying, trackUrl]);

    useEffect(() => {
        if (!audioElement) {
            return;
        }
        try {
            audioElement.play();
            setIsPlaying(true);
        } catch (e) {
            setIsPlaying(false);
            console.error(e);
        }
    }, [audioElement]);

    useEffect(() => {
        if (!audioElement) {
            return;
        }
        try {
            if (!isPlaying) {
                audioElement.pause();
            }
        } catch (e) {
            console.error(e);
        }
    }, [isPlaying, audioElement]);

    return (
        <MusicContext.Provider value={ctx}>
            {trackUrl ? (
                <audio
                    key={trackUrl}
                    autoPlay={true}
                    src={trackUrl}
                    loop
                    ref={(audio) => {
                        setAudioElement(audio);
                    }}
                />
             ) : null}

            {children}
        </MusicContext.Provider>

    )
}
