const themes = {
    default: {
        backgroundColor: '#000',
        textColor: 'white',
        secondaryTextColor: '#999',
        dividerColor: 'rgba(255, 255, 255, 0.25);',
        headerFont: 'PT serif, serif',
        textFont: 'PT mono, monospace',
        wackyFont: 'Adieu Regular',
        SongCard: {
            background: 'rgb(145, 125, 99)',
        }
    }
}

export default themes;
