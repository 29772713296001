import { makeS3Video } from "../clients/DriveVideosClient";
import Moment from "../models/Moment";
import { driveVideoToMoment } from "./useMomentsForSong";
// Lana del rey —> First aid kit - Emmylou (22auw1DYQlYh5XgKv5FtNx)
// Heads will roll —> M83 - Midnight City (6GyFP1nfCDB8lbD2bG0Hq9)
// ni**as in paris —> Mac Miller - The Spins (51pshtuYkgUQnt5huMPbKL)
// Aviicii - Theophilus London - Only You (2rDwETmcGw4ZqbuXvLQmDL)
// osama —> L.A.X - Gwara Gwara (Baddest version) (7M0srxuBmdn4NjX5RObT7c)
interface Song {
    songName: string;
    artistName: string;
    trackId: string;
    momentUrls: string[];
    fileNames: string[];
}

export const testDB: Song[] = [
    {
        songName: "Intervention",
        artistName: "Arcade Fire",
        trackId: "7Bl5Fqa2g1UFs2O1VJiKhb",
        momentUrls: [
            "14aOpfzqpVc9ugbJ1FTE5NGdv7u4KDDhc",
            "13vJUVx5ZsOdGno1hfbtaaJceOnTnmPCL",
            "1qelSf9wnTkRypLMGO9eQ3P-Ho27ueq0x",
            "15f-1gOr5i9FH_d6QO9Y3cpeQZGh--h8A",
            "119NGJiOofMSKC0CzLgPsJsGo1iDmGJ4-",
            "1mtg-bXCGgxCEBUlDKGpTsuIXgEnbegT2",
            "1vjoTmJn2Pbd98jf3vfsWC1xscDPGKaoU",
            "1_P3sH6KVI469KMEFbaaSt3ROqsjTdvIK",
            "1PxtnKbqyL1maOt5edSHHnx6OCSh_oMna",
            "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
        ],
        fileNames: [
            "arcade_IMG_2814_1.mp4",
            "arcade_IMG_4810_1.mp4",
            "arcade_IMG_5208.TRIM_1.mp4",
            "arcade_IMG_6403_1.mp4",
            "arcade_IMG_6632_1.mp4",
            "arcade_IMG_6838_1.mp4",
            "arcade_IMG_7109_1.mp4",
            "arcade_IMG_7497_1.mp4",
        ],
    },

    {
        songName: "Born in the U.S.A",
        artistName: "Bruce Springsteen",
        trackId: "0dOg1ySSI7NkpAe89Zo0b9",
        momentUrls: [
            "1GsjYIJ5uHfdJL7AIIVxkYQyjRHxCZCMj",
            "1FRWNO3nDWMJ88-9K-bZ0Sm-OVxCrJqBo",
            "1jrO_dr3Gb3XJ1bYGYtUyQDNQCuKIclCA",
            "1tBrusgbv6rCG2k6zduwNo4C35UXD5wnr",
            "16dAvplmq5Bkknz0EshlET9xfy1hB7HiN",
            "1e-Y5xl4-Piyb2IOmsYKUW7Dg4RQrUcXN",
            "1zg98kgQ0TLLLWvAqqLh9F7pf_IiaaAyF",
            "14qDzsGXiVTQ-dhCY4Fnzlf2uqpfIIK4q",
            "1nWj3e6a_RdMfFWtjOzdh6g_VupZjNb-D",
            "1uQp2RqwLqWkQq-3BkLEed7_JyjlAhHji",
            "1VcgEd0_Ajob8efXGD70UU_GtV3vvptCk",
            "1nPbjuyu96ZpTl3TXBNZhuck6iPp4wVzS",
            "1VZKByboaXNQKCBleu6F2xqV8SgmPuSD2",
            "16p2ERjXGVFidyJaVxzlu6ggdoQdwmPN4",
            "1NlCmK5ygcxBBhqnmCjCC7diF_oVaRxMK",
            "1ZGTvwyakv6hgQ5vGVaoMHt_755E1eDeZ",
            "1FYsIvjyAp1inJzZAy99AUyvsa4iDDHXJ",
            "1unpEHHhPmSTjg7HXBkdoyxU_g_e69EUu",
            "1fHJV9FJBeV0qyy9NvhH-uftJUR-Vu5h5",
            "1aTXY24swdcd2kBD0QSF7KFcb7PB9pMJg",
            "19ZRMFuh1wSKF3p4jil0QWLjPckjwRdhT",
            "1WUHpJpfOorzgDoGz-gCxScWv4G9hom4H",
            "12Opronfjg-LqRYINfLYDyAf2C-wN3VtB",
            "1nyY4g4cdWkg-ajsTKpBIkaV11TS3Bnnm",
            "1gAEbsRQE13kdlRn9bgYC5JvtR7Zg_Ri8",
            "15rXUf2EHsXgSs_ca_0InHna8lBZCRoS5",
            "1R0qLs75Yah-DY-svTaQeV-I_y-Qd3NOg",
            "183q4W6Wy76MNiD2UmQdHx5MlWteFE0wD",
            "1xjsJf9EZ7UUuYyIigRNafnH8SLWSHGS0",
            "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
        ],
        fileNames: [
            "born_IMG_0478_1.mp4",
            "born_IMG_0501_1.mp4",
            "born_IMG_1159_1.mp4",
            "born_IMG_1622.TRIM_1.mp4",
            "born_IMG_1813_1.mp4",
            "born_IMG_2314_1.mp4",
            "born_IMG_2726_1.mp4",
            "born_IMG_2984_1.mp4",
            "born_IMG_3926_1.mp4",
            "born_IMG_4570_1.mp4",
            "born_IMG_5911_1.mp4",
            "born_IMG_6259_1.mp4",
            "born_IMG_6418_1.mp4",
            "born_IMG_6420_1.mp4",
            "born_IMG_6652_1.mp4",
            "born_IMG_7469_1.mp4",
            "born_IMG_7470_1.mp4",
            "born_IMG_8154.TRIM_1.mp4",
            "born_IMG_8214_1.mp4",
            "born_IMG_8921_1.mp4",
            "born_IMG_9307_1.mp4",
            "born_IMG_9417_1.mp4",
            "born_IMG_9527_1.mp4",
            "born_IMG_9733_1.mp4",
            "born_IMG_9735_1.mp4",
            "born_IMG_9842_1.mp4",
            "born_IMG_9875_1.mp4",
            "born_IMG_9883_1.mp4",
            "born_video 2_1.mp4",
        ],
    },

    {
        songName: "Can't stop the feeling",
        artistName: "Justin Timberlake",
        trackId: "4sQmCQUZcnBPaVm4dEUKv7",
        momentUrls: [
            "1Gc4M-RnbiC9CazKAlw4Wze9fm8rkaFnA",
            "1xbLRcSdZc5o7MUtPtAmzO5ckhn0ulkg5",
            "1Ox9_n-bGQXGuO0jKU3wwmAjIZmWMIo9S",
            "1KfyK9zWlgz3AQQBJS4BGRdoZ3eGk6ks4",
            "1bZrXAxaHeYSItjOC3z8VMzJQQZElqh39",
            "1ZLhXLNQ8whDF_ChnMUD7wGgJ0BZFNUNn",
            "1tPUZprtdLivfBUGAePcm7Vf1m2D_zZKh",
            "1GlCt6BxmvGuP9pQUEjmezVYNEBtzB0Nv",
            "1BMakgllbNzZjt1dDmy9x6V1A2hqFJdau",
            "1qRTmPUYCSgrtEzDi6NNC8FXXGWY_3iBF",
            "1WcOS3NuxK8EZfMRsOLq5X3LNOTJte7K0",
            "1Ha-k3CvTuRBq4RatA-Pwa2F10ynfeo-W",
            "1DuKWfkQEPhO8QnVSrzD0rpQRzKPaJqjd",
            "1eeldYqch73S8VyI9WRmVjLqOExlE3ZoL",
            "1BWBfd8VNXcwKTX2Q8ahNt5gEVZsa4liS",
            "1RaCzw8MjljFiaH1Pr0FRP-aA8m_SiFQk",
            "1lhNl0kkm9GPi1PqO4pYBEY_h1R9diW7D",
            "1unEQ6OxOMw24FhMSjsK3nLc70-huPR9N",
            "12M1hFvZ7zC1qO-lvyIilQL53E3gH_8C5",
            "1OGEegOqrarfAtST19HWJ_7xYixLRDs0v",
            "1Z79SiOJyXXMjEioAajmmeN3gnsWNewFS",
            "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
        ],
        fileNames: [
            "cant_stop_809DB82A-BB85-4447-84A1-AE1D8CD48601_1.mp4",
            "cant_stop_Flipper_1.mp4",
            "cant_stop_IMG_0059_1.mp4",
            "cant_stop_IMG_0191_1.mp4",
            "cant_stop_IMG_0630_1.mp4",
            "cant_stop_IMG_0964_1.mp4",
            "cant_stop_IMG_0973_1.mp4",
            "cant_stop_IMG_2589_1.mp4",
            "cant_stop_IMG_3789_1.mp4",
            "cant_stop_IMG_4116_1.mp4",
            "cant_stop_IMG_4256_1.mp4",
            "cant_stop_IMG_5695_1.mp4",
            "cant_stop_IMG_8168_1.mp4",
            "cant_stop_IMG_8296_1.mp4",
            "cant_stop_IMG_8424_1.mp4",
            "cant_stop_IMG_8785_1.mp4",
            "cant_stop_player_export (1)_1.mp4",
            "cant_stop_Taiba Kite Stories_1.mp4",
            "cant_stop_trim.02BCB18A-4E5B-4B87-9593-83B7E09410C0_1.mp4",
            "cant_stop_WhatsApp Video 2022-02-23 at 16.46.37_1.mp4",
            "cant_stop_WhatsApp Video 2022-02-23 at 16.46.38_1.mp4",
        ],
    },

    {
        songName: "Midnight City",
        artistName: "M83",
        trackId: "6GyFP1nfCDB8lbD2bG0Hq9",
        momentUrls: [
            "1hw-DDUayauI7jv2zNPEPiUJhbZuHuTzd",
            "1Yu-gKCA5tBTxH7kvkGn0Rne_dV54vgJh",
            "16_fdvLK3UHKbAssYDe2TIyaiaMNN6XDa",
            "1z3ZAPz-h6TGemiTVyk6Vdc5dklN5CWUZ",
            "1YiBHl3bHY7tQ_c1ozV90aHQlkFWyH0ot",
            "1b0X2k5bbJ02J0c-VOOM_XY2sih9LwMgy",
            "1q5c0BjCLIl9gUMMfJtF2xVu5iq0ReEBy",
            "1bgIli9cdXtoM_xDe0txY5kVD6VTbwPS-",
            "1YA7PylemyxAjvvMMSLHmLOVW422Li0MP",
            "1mgdxVBiTz9yzrnS47YkKd-FANtnYBIkH",
            "1xrkZ60dPZGov-GMhFBrxxdVhKZc9d7fk",
            "1P_mhonxcw8HATn7OKYVl-fYr6kess-Ul",
            "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
        ],
        fileNames: [
            "heads_roll_High jump_1.mp4",
            "heads_roll_IMG_0093_1.mp4",
            "heads_roll_IMG_0236_1.mp4",
            "heads_roll_IMG_1972_1.mp4",
            "heads_roll_IMG_2339_1.mp4",
            "heads_roll_IMG_3662_1.mp4",
            "heads_roll_IMG_7497_1.mp4",
            "heads_roll_IMG_7549_1.mp4",
            "heads_roll_IMG_8119_1.mp4",
            "heads_roll_IMG_9461 2_1.mp4",
            "heads_roll_IMG_9461_1.mp4",
            // "heads_roll_v09044g40000c5o9gnrc77u9bncikau0_1.mp4",
        ],
    },

    {
        songName: "Take me home, country roads",
        artistName: "John Denver",
        trackId: "1QbOvACeYanja5pbnJbAmk",
        momentUrls: [
            "1U3VIPC9SbOwEsSafgYmfHTb8tlR_nEdG",
            "1JkgRBnlzZKbcod4XVmitdgVYwcTyCFzH",
            "1NGSBIOlSt_woCDE_NeyDD66nT034zpf-",
            "1cqzaknSz78O9Gj0uR0QYLeo-YXUuzhm9",
            "1oH2HGX6Dw244wJeiMF0ggd28IbsYnzTX",
            "1vu3sEUyXEYWbP3fFn2rC0GTdBNeGPE-9",
            "1mp2Ki0FdkR3Cj7Kogrms4GLvMnH17g47",
            "19XSwh5emvWFubN2veJfhTKTbV5rmtfzp",
            "1jYIyK5mozFgr-ChljwpHpTcpCALPtaVP",
            "19lTKeBpVCygzcStUiZqzp3oQmbqNMyqM",
            "1iNy8Upfgh-B0hOQ7wow-ViZf8kvKJEKf",
            "1uni3O5-2LuHIm7SdXlIaRR14n2SvkGe8",
            "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
        ],
        fileNames: [
            "denver_2_cowboy_1.mp4",
            "denver_3_cowboy_1.mp4",
            "denver_4_cowboy_1.mp4",
            "denver_5_cowboy_1.mp4",
            "denver_7_cowboy_1.mp4",
            "denver_cowboy 6_1.mp4",
            "denver_Cowboy_8_1.mp4",
            "denver_cowboy_9_1.mp4",
            "denver_denver_1_cowboy_1.mp4",
            "denver_Screen Recording 2022-03-17 at 15.53.11_1.mp4",
            "denver_Screen Recording 2022-03-17 at 15.54.21_1.mp4",
            "denver_Screen Recording 2022-03-17 at 15.57.47_1.mp4",
        ],
    },

    {
        songName: "Still don't know my name",
        artistName: "Labrinth",
        trackId: "6N22FZs2ZhPBYi3b9XPajV",
        momentUrls: [
            "1qftbVMk-UrMsXe8HdT7H8SWm_nMLPxmO",
            "1jER026vYryC1TPDlMbF9pT5lpWPj8Hj0",
        ],
        fileNames: [
            "Still_Dont_know_my_name_1_Labrinth_1.mp4",
            "Still_Dont_know_my_name_2_1.mp4",
            "Still_Dont_know_my_name_2_Labrinth_1.mp4",
            "Still_Dont_know_my_name_3_1.mp4",
            "Still_Dont_know_my_name_4_1.mp4",
            "Still_Dont_know_my_name_4(1)_1.mp4",
            "Still_Dont_know_my_name_5_1.mp4",
            "Still_Dont_know_my_name_6_1.mp4",
            "Still_Dont_know_my_name_7_1.mp4",
            "Still_Dont_know_my_name_8_1.mp4",
            "Still_Dont_know_my_name_9_1.mp4",
            "Still_Dont_know_my_name_10_1.mp4",
            "Still_Dont_know_my_name_11_1.mp4",
            "Still_Dont_know_my_name_12_1.mp4",
            "Still_Dont_know_my_name_13_1.mp4",
            "Still_Dont_know_my_name_14_1.mp4",
            "Still_Dont_know_my_name_15_1.mp4",
            "Still_Dont_know_my_name_16_1.mp4",
            "Still_Dont_know_my_name_17_1.mp4",
            "Still_Dont_know_my_name_18_1.mp4",
            "Still_Dont_know_my_name_19_1.mp4",
            "Still_Dont_know_my_name_20_1.mp4",
            "Still_Dont_know_my_name_21_1.mp4",
            "Still_Dont_know_my_name_22_1.mp4",
            "Still_Dont_know_my_name_23_1.mp4",
            "Still_Dont_know_my_name_24_1.mp4",
            "Still_Dont_know_my_name_25_1.mp4",
            "Still_Dont_know_my_name_26_1.mp4",
            "Still_Dont_know_my_name_27_1.mp4",
            "Still_Dont_know_my_name_28_1.mp4",
        ],
    },

    {
        songName: "Emmylou",
        artistName: "First aid kit",
        trackId: "22auw1DYQlYh5XgKv5FtNx",
        momentUrls: [
            "1stkIi8UDLHah1q4AqdYg2Vj75ojgljfF",
            "1M8pwq4vZRjI_i5SX3EK64WC7WFPxZFAd",
            "1a8V-o3XHg6M-Hn-x-QoEx67Ru2NrhaUV",
            "1QSdTJafb7j84gZbRfyKtmgXLlJn38tSf",
            "1yhAfrNwQCNX2ZhAH2K4LvIhV7ieo-goj",
            "19ifVVhzUZsCkEFRjA1hiVrmxJrNiX7YB",
            "1nB9BIxMB7iMQrLURmmykV4pResLA4D9j",
            "1AdB8pOqFUYCv7JJB1bls0MowuoXCMrvl",
            "1jZ2GWCl_1ssl-wepdpFWmuioNukioqPI",
            "19-5eX6QRL8isEq7LWaQu1vGlJH5pYT4D",
            "1XQgvNMP1L6NYnsRxWWMRWmOLJzJ7fX8i",
            "1zAWG9NigWGhWz11c7780pjRoJtM4AdcP",
            "17kgGYhY6FR2z4Fa1A43Rb-kE-mVHFBYg",
            "1s3d0-Vj8bsgiYVrLEAxg8PqDy-Hau4Rk",
            "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
        ],
        fileNames: [
            "Video_Games_1.mp4",
            "Video_Games_2_1.mp4",
            "Video_Games_3_1.mp4",
            "Video_Games_4_1.mp4",
            "Video_Games_5_1.mp4",
            "Video_Games_6_1.mp4",
            "Video_Games_7_1.mp4",
            "Video_Games_8_1.mp4",
            "Video_Games_9_1.mp4",
            "Video_Games_10_1.mp4",
            "Video_Games_11_1.mp4",
            "Video_Games_12_1_1.mp4",
        ],
    },

    {
        songName: "The Spins",
        artistName: "Mac Miller",
        trackId: "51pshtuYkgUQnt5huMPbKL",
        momentUrls: [
            "1uN83PrMyS3LHyErZ6QiFT6bwPgIvQZcH",
            "1XfoJnni6BdrtA8D1cMK9DqEljt5VNIOb",
            "1Pske4odANna346dBaAWtPrQ3cw6kOJGb",
            "1QT7O8hEbXVdLvMFdQp4N2BoKk4U6jQZK",
            "1qhgY3mFckOItdyAn9EKA-W0l6QAWeJ5b",
            "13EKTPMNQm2eGs-DkvyDHVxPQSRpQfe27",
            "1M2EQolPeSiL1VevW2hYdi_XPgj6saRiT",
            "149RQE7mpWs-wFQudfoQxGggHAWi9i5T5",
            "1hLzrmOfhUQd5tzRtutolOpf3KZlkKHUt",
            "10Efwm3YVhAL06FG_0UXGhyhDbfTqp1Yn",
            "1FySXuWUv9efdm-2llHJ44bC5j04VRRxJ",
            "1VD4ompp0omlAEZUk2_uzKbb84NOpl7aj",
            "1oVX3xp3DUftjFplouYcbBfzxsekRHbX2",
            "1KwtOdKpzNfjpUUQB3m_4wXQo_Iazy17e",
            "1a6sADHugcyqX7YH9v3Ko79d19IPMbbBH",
            "1riXL0NF9Dsq8smYm8iM_JKhxUhUFxHbg",
            "16gWLBHbum48BObOoFnGu09m8XKFOnuyE",
            "1AXP3u7Hxi0Xz-U_Cb7XOahJMwh4VlMrx",
            "17lZxMtr2ynyx2cc6k1kj9dlGyBBBJo7v",
            "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
        ],
        fileNames: [
            "ni_as_af52f7d1-e281-4147-9b5c-f3961a14efab_1.mp4",
            "ni_as_IMG_0161_1.mp4",
            "ni_as_IMG_0686_1.mp4",
            "ni_as_IMG_2440_1.mp4",
            "ni_as_IMG_3325_1.mp4",
            "ni_as_IMG_4044_1.mp4",
            "ni_as_IMG_4362_1.mp4",
            "ni_as_IMG_4803_1.mp4",
            "ni_as_IMG_5524_1.mp4",
            "ni_as_IMG_6134_1.mp4",
            "ni_as_IMG_7008_1.mp4",
            "ni_as_IMG_7410_1.mp4",
            "ni_as_IMG_7461_1.mp4",
            "ni_as_IMG_7785_1.mp4",
            "ni_as_IMG_8255_1.mp4",
            "ni_as_IMG_8422_1.mp4",
            "ni_as_IMG_8508_1.mp4",
            "ni_as_MG_1863.TRIM_1.mp4",
            "ni_as_Sunday night lights mob_1.mp4",
        ],
    },

    // {
    //     songName: "Gwara Gwara (Baddest version)",
    //     artistName: "L.A.X",
    //     trackId: "7M0srxuBmdn4NjX5RObT7c",
    //     momentUrls: [
    //         "1rVzgmPZLYs3szruEfpjpelvzZKb_Gad7",
    //         "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
    //     ],
    //     fileNames: [
    //         "Osama - Zakes Bantwini/osama_viola.MP4",
    //         "Osama - Zakes Bantwini/viola.MP4",
    //     ],
    // },

    {
        songName: "Monomom",
        artistName: "Alva Noto",
        trackId: "083hSYQL8F0UScdHIXDBTN",
        momentUrls: [
            "1EW_LtoqBDtvQyWkLoo1i-7FsXI-8O9tX",
            "1Bm81rdx5sgPuxKrVPqHhcrJuj47ZeNOU",
            "1qbfMv5lO1tvL-uOX9QCBqNWBVNt8eWQW",
            "1Y54U_lkKTmCjb8LXxWx6CMYPeY0u2SEr",
            "11DsKGOt9aMIwG5nCC92C4NENxJypN86i",
            "1i2axo0m7AhprFnmteRMlmZnwmmmhi0Ju",
            "1LIjJWsUXIFQvQjXL4_gkiMGLhT5tIymS",
            "1_O-JTpcTGMrQXNI9SfZvNpTDAfaUipVj",
            "1KIPTjp0nQYLZqHjpjQ5uILJHS2WW1JYy",
            "1PvI9b6owS7pcw-GVmo7Pc1cXbzOFRxGx",
            "1OkcbwSZ_QsW03fO3b14mBH9R8K_Lp3ZA",
        ],
        fileNames: [
            "sakamoto_2021-12-28 17.57.15_1.mp4",
            "sakamoto_2022-01-01 02.03.33_1.mp4",
            "sakamoto_2022-01-03 23.36.06_1.mp4",
            "sakamoto_2022-01-14 16.04.19_1.mp4",
            "sakamoto_2022-01-26 13.43.12_1.mp4",
            "sakamoto_2022-01-27 19.09.38_1.mp4",
            "sakamoto_2022-02-05 01.52.53_1.mp4",
            "sakamoto_2022-02-09 14.02.31_1.mp4",
            "sakamoto_2022-03-04 19.18.54_1.mp4",
            "sakamoto_2022-03-09 13.35.55_1.mp4",
            "sakamoto_2022-03-09 18.49.57_1.mp4",
        ],
    },

    {
        songName: "Only You",
        artistName: "Theophilus London",
        trackId: "2rDwETmcGw4ZqbuXvLQmDL",
        momentUrls: [
            "1n7fcTjKO0B2Kmdw8Lp-TfYagwBfJIa-V",
            "1SbmBHmfHa2dvhbH2by169Cbtsd2Fcago",
            "1sOU9qo1zAmfj7DrmAXxmGQ4uNSwY38z2",
            "1HLGs5WRK5HxAY3g4fpxycRBFugi1anpM",
            "191RunLk6kSNFUt2cyYk8-z1iDtoVdvk9",
            "1JhxWHgK-sq033yafNp2IUczHCjOu9sR1",
            "11mGEL120Hlur2m4J0kfNojilcEnlrfad",
            "1H4e-jx5LgiTaH0X_GSF_mG8mC0X-fT7V",
            "1GngZ3JGZ9UP4B_6hBR_gf5ydip4h5rzQ",
            "1vEEBwK4y0UqUXX2XTWj9-QwIHR14efMo",
            "15sXOb_CGp3BAz9ZSZboubXD7lMYZJE2T",
            "1DhHgiFZ4NnDOicZ-BBmWJnzkYg-zWSof",
            "1vkYLHoEru8-zJ7eEpiP3hOrGbRdLO7DU",
            "1dbkYjVa632yWcY2GgMLyhsq29VvxW9h-",
            "1pb60AA8BJshEdwuYLWkbp34JVpoMHHz5",
            "1jblp6o982k1nOJ6rj5lkncKn9_oeekVm",
            "1FJ1on0OLRjIymhOO8oNsI4TDNi26tIrC",
            "1UZDNFmku1wla2091adYefw-TU7pCf32J",
            "1YYdg4oEC6cGBWJbbgzlBbcke4ucHbhrc",
            "129r-e07Ww9fBv1sdOK1SYAKf1EhVOQ4I",
            "1xKNstsI4eFkqJJVcDpGhFPiCWq2cAEqX",
            "1QLgdMKC5-M3Gk46J6StFwY5DVOHQLI52",
            "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
        ],
        fileNames: [
            "avicii_45bb58d2ed554204b199f70b5c4ea783_1.mp4",
            "avicii_64703794709__5150112B-D7ED-4B84-B5E0-A852D9952509_1.mp4",
            "avicii_IMG_0017_1.mp4",
            "avicii_IMG_0103_1.mp4",
            "avicii_IMG_0106_1.mp4",
            "avicii_IMG_0168_1.mp4",
            "avicii_IMG_0196_1.mp4",
            "avicii_IMG_0415.TRIM_1.mp4",
            "avicii_IMG_0631_1.mp4",
            "avicii_IMG_0700_1.mp4",
            "avicii_IMG_0958_1.mp4",
            "avicii_IMG_2441_1.mp4",
            "avicii_IMG_4014_1.mp4",
            "avicii_IMG_4429_1.mp4",
            "avicii_IMG_4741_1.mp4",
            "avicii_IMG_5283_1.mp4",
            "avicii_IMG_5284_1.mp4",
            "avicii_IMG_6779_1.mp4",
            "avicii_IMG_7265_1.mp4",
            "avicii_IMG_8176_1.mp4",
            "avicii_IMG_9052_1.mp4",
            "avicii_player_export_1.mp4",
        ],
    },

    {
        songName: "Yoshi City",
        artistName: "Yung Lean",
        trackId: "5Tou8lYopj9qCIFBFBomZ5",
        momentUrls: [
            "18OHEY2DWqIypUm14nplObRivryHw2ho1",
            "1m99ZypnWX1mtElzupPGCNCsWHtEWYQg7",
            "1EysdGJsQ5kBXpg4-SRBS-g6uI81iVI5v",
            "1JJNyKBQPo9MUZ1LHAOERdU-R0QPm_G4d",
            "1xrnsMLg0yC-w43nzSP5AhSVPlxDOXHtC",
            "1Ijy_VTlZ3wjc9Jp2I60DS5xulIb4TAXx",
            "1WuorYVV0Q-CcmCPbDp53o5DtcIFIcUPV",
            "1OgupX0Y78vd46VpvIYinVZ0nYAZVtwga",
            "1fLPgBuB3ymQSWqvSkIAnAaGU49lNAZYL",
            "1dZvTQiK2M_g4FAwF-y0k7Jb7RqhR2Kn5",
            "1UGONPm89m8P3t5ROq6oSaB8FD8rBl5wg",
            "1s6lfeVoi4PDUaI81JdAYaShBrCnwusgd",
            "16pnMJELSs9B4DaKzLIJfSfazf72JCQGv",
            "1jDHbklsAbCc3LYyKD6Vn1H_dcHrip6Hy",
            "1Q6tZiyoSod3EA9e5xUUj1Y83_F42LGL0",
        ],
        fileNames: [
            "Yoshi_City_1_bike_1.mp4",
            "Yoshi_City_2_bike_1.mp4",
            "Yoshi_City_3_bike_1.mp4",
            "Yoshi_City_4_bike_1.mp4",
            "Yoshi_City_5_bike_1.mp4",
            "Yoshi_City_6_bike_1.mp4",
            "Yoshi_City_7_bike_1.mp4",
            "Yoshi_City_8_bike_1.mp4",
            "Yoshi_City_9_bike_1.mp4",
            "Yoshi_City_10_bike_1.mp4",
            "Yoshi_City_shoutoutboyz_1.mp4",
            "Yoshi_City_shoutoutboyz2_1.mp4",
            "Yoshi_City_shoutoutboyz3_1.mp4",
            "Yoshi_City_shoutoutboyz4_1.mp4",
        ],
    },

    // {
    //     songName: "",
    //     artistName: "",
    //     trackId: "",
    //     momentUrls: []
    // },
];

export function getRandomSong(): Song {
    return testDB.sort(() => Math.random() - 0.5)[0];
}

export function getRandomMoments(total: number): Moment[] {
    return testDB
        .map((p) => {
            const videoUrl = p.fileNames.sort(() => Math.random() - 0.5)[0];
            return driveVideoToMoment(makeS3Video(videoUrl), p.trackId);
        })
        .slice(0, total);
}

export const twitterUsers = [
    {
        name: "@james_hammerman",
        profileImage: "https://pbs.twimg.com/profile_images/1100405110402834432/nurE6owq_400x400.png",
    },
    {
        name: "@erikrothoff",
        profileImage: "https://pbs.twimg.com/profile_images/600228843979411456/pClcHmxx_400x400.jpg",
    },
    {
        name: "@sven_karlsson",
        profileImage: "https://pbs.twimg.com/profile_images/1308769664240160770/AfgzWVE7_400x400.jpg",
    },
    {
        name: "@ron_stolero",
        profileImage: "https://pbs.twimg.com/profile_images/1215070700026855425/7edvU72D_400x400.jpg",
    },
    {
        name: "@p4tte",
        profileImage: "https://pbs.twimg.com/profile_images/1394384156805804034/l4kg3Sdj_400x400.jpg",
    },
    {
        name: "@flaming_dragon22",
        profileImage: "https://pbs.twimg.com/profile_images/1486216617683947522/9UuhdhkF_400x400.jpg",
    },
    {
        name: "@MarcBramaud",
        profileImage: "https://pbs.twimg.com/profile_images/602547483978366976/yRMlIpKf_400x400.jpg",
    },
    {
        name: "@NicklasSoder",
        profileImage: "https://pbs.twimg.com/profile_images/1149241692454825984/1MawvRaI_400x400.jpg",
    },
    {
        name: "@JaneyLee",
        profileImage: "https://pbs.twimg.com/profile_images/1495578694928134145/H4VEeSFu_400x400.png",
    },
    {
        name: "@YuliyaK",
        profileImage: "https://pbs.twimg.com/profile_images/1460669261052096519/mjYPVTO4_400x400.jpg",
    },
    {
        name: "@MuratAyan",
        profileImage: "https://pbs.twimg.com/profile_images/1221041393369604096/gakXlg3i_400x400.jpg",
    },
    {
        name: "@JoostRemijn",
        profileImage: "https://pbs.twimg.com/profile_images/961584402869432320/Arnuyxeo_400x400.jpg",
    },
    {
        name: "@LisaOlsson",
        profileImage: "https://pbs.twimg.com/profile_images/781146415888273409/iSlkrhRd_400x400.jpg",
    },
    {
        name: "@Akanksha",
        profileImage: "https://pbs.twimg.com/profile_images/1391494820477259781/Or_OqBx1_400x400.jpg",
    },
    {
        name: "@Cliff",
        profileImage: "https://pbs.twimg.com/profile_images/1500989517909700610/NpXTPVs7_400x400.jpg",
    },
    {
        name: "@EzRaRa",
        profileImage: "https://pbs.twimg.com/profile_images/1489851575266955269/-9ZhFR0i_400x400.jpg",
    },
];

export function getRandomTwitterUser() {
    return twitterUsers[Math.floor(Math.random() * twitterUsers.length)];
}
