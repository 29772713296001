import { SpotifyTrack } from "../clients/SpotifyClient";
import Album from "./Album";
import Artist from "./Artist";

export default interface Song {
    id: string;
    name: string;
    album: Album;
    artist: Artist;
    momentsCapacity: number;
    momentsLeft: number;
    previewUrl: string;
}

export function spotifyTrackToSong(spotifyTrack: SpotifyTrack): Song {
    return spotifyTrack.song;
}
