import React from "react";
import styled from "styled-components";

const LoadingScreen: React.FC = () => {
    return (
        <Container>
            Loading...
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 300px;
`

export default LoadingScreen;
