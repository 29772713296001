import React from "react";
import { share } from "rxjs";
import styled from "styled-components";
import { BigHeader, TopLogo } from "../components/ui";

const SharePage: React.FC = () => {
    const share = (what: string) => {
        if (what === "portals") {
            document.location.href = "https://theportal.to/?room=783b1b2a-26e7-4a03-a275-384f8f217c50&ServerCode=0";
        }
    }

    return (
        <Container>
            <TopLogo />

            <BigHeader>
                Share and connect
            </BigHeader>

            <ShareBackground />

            <ShareContainer>
                <ShareItem>
                    <ShareThing
                        src={require("../assets/share/portals.png")}
                        onClick={() => share("portals")}
                    />
                </ShareItem>
                <ShareItem>
                    <ShareThing
                        src={require("../assets/share/twitter.png")}
                        onClick={() => share("twitter")}
                    />
                </ShareItem>
                <ShareItem>
                    <ShareThing
                        src={require("../assets/share/facebook.png")}
                        onClick={() => share("facebook")}
                    />
                </ShareItem>
                <ShareItem>
                    <ShareThing
                        src={require("../assets/share/snap.png")}
                        onClick={() => share("snap")}
                    />
                </ShareItem>
                <ShareItem>
                    <ShareThing
                        src={require("../assets/share/youtube.png")}
                        onClick={() => share("youtube")}
                    />
                </ShareItem>
                <ShareItem>
                    <ShareThing
                        src={require("../assets/share/instagram.png")}
                        onClick={() => share("instagram")}
                    />
                </ShareItem>
                <ShareItem>
                    <ShareThing
                        src={require("../assets/share/tiktok.png")}
                        onClick={() => share("tiktok")}
                    />
                </ShareItem>
                <ShareItem>
                    <ShareThing
                        src={require("../assets/share/discord.png")}
                        onClick={() => share("discord")}
                    />
                </ShareItem>
            </ShareContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ShareBackground = styled.img.attrs({
    src: require("../assets/share/share-bg.png"),
})`
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
`

const ShareContainer = styled.div`
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row dense;
    align-items: center;
    justify-content: center;
    gap: 45px 0px;
    text-align: center
`

const ShareItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ShareThing = styled.img`
    width: 66px;
    align-self: center;
`

export default SharePage;
