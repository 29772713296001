import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { getColorFromImage } from "../data/getColorFromImage";

type ImageStyle = "circle" | "square";
type ImageSize = "small" | "full";

interface Props {
    src: any;
    imageStyle: ImageStyle;
    size: ImageSize;
    spin?: boolean;
    didGetColor?: (color: string) => void;
}

const AlbumCover: React.FC<Props> = ({ src, imageStyle, size, spin, didGetColor }) => {
    const [imgElement, setImgElement] = useState<HTMLImageElement | null>(null);

    useEffect(() => {
        if (!imgElement || !didGetColor) {
            return;
        }

        try {
            const color = getColorFromImage(imgElement);
            didGetColor(`rgb(${color.r}, ${color.g}, ${color.b})`);
        } catch (e) {}

        setTimeout(() => {
            const color = getColorFromImage(imgElement);
            didGetColor(`rgb(${color.r}, ${color.g}, ${color.b})`);
        }, 50);
    }, [imgElement, didGetColor]);


    switch (imageStyle) {
        case "circle":
            return (
                <SpinContainer>
                    <CircleContainer size={size} spin={!!spin}>
                        <AlbumImage
                            key={src}
                            onLoad={(e) => setImgElement(e.target as HTMLImageElement)}
                            src={src}
                            crossOrigin="anonymous"
                            // ref={(ref) => setImgElement(ref)}
                        />
                        <CircleMaskImage />
                    </CircleContainer>
                </SpinContainer>
            )

        case "square":
            return (
                <SpinContainer>
                    <SquareContainer size={size} spin={!!spin}>
                        <SquareMaskImage />
                        <AlbumImage
                            key={src}
                            onLoad={(e) => setImgElement(e.target as HTMLImageElement)}
                            src={src}
                            crossOrigin="anonymous"
                        />
                    </SquareContainer>
                </SpinContainer>
            )
    }
};

const SpinContainer = styled.div`
    perspective: 180px;

    @keyframes spinAnimation {
        0% {
            transform: rotateY(0);
        }
        50% {
            transform: rotateY(180deg);
        }
        100% {
            transform: rotateY(360deg);
        }
    }

`

interface ContainerProps {
    size: ImageSize;
    spin: boolean;
}

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;

    ${(props) =>
        props.size === "small" &&
        css`
            width: 90px;
            height: 90px;
        `}

    ${(props) =>
        props.spin &&
        css`
            transform-style: preserve-3d;
            animation: spinAnimation 4s linear infinite;
        `}
`;

const SquareContainer = styled(Container)`
    padding: 0.5em;
`;

const CircleContainer = styled(Container)`
    border-radius: 1000px;
`;

const AlbumImage = styled.img`
    width: 100%;
    position: relative;
    z-index: 2;
`;

const CircleMaskImage = styled.img.attrs({
    src: require("../assets/qr-code-circle.png"),
})`
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
`;

const SquareMaskImage = styled.img.attrs({
    src: require("../assets/qr-code-square.png"),
})`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
`;

export default AlbumCover;
