import React from "react";
import styled from "styled-components";
import Moment from "../models/Moment";
import MomentPlayer from "./MomentPlayer";

interface Props {
    moment: Moment;
}

const MomentThumb: React.FC<Props> = ({ moment }) => {
    return (
        <Container>
            <MomentPlayer moment={moment} />
        </Container>
    )
}

const Container = styled.div`
    overflow: hidden;
    width: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default MomentThumb;
