import { useEffect, useState } from "react";
import Song from "../models/Song";
import useSpotifyClient from "./useSpotifyClient";

export default function useSong(id: string | undefined): Song | undefined {
    const [song, setSong] = useState<Song | undefined>(undefined);
    const spotify = useSpotifyClient();

    useEffect(() => {
        if (!id || !spotify) {
            return;
        }

        let changed = false;

        const doer = async () => {
            const track = await spotify.track(id);
            if (track && !changed) {
                setSong(track.song);
            }
        }

        doer();

        return () => {
            changed = true;
        }
    }, [id, spotify]);

    return song;
}
