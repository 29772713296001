import { createGlobalStyle } from 'styled-components';

const AdieuRegularWoff = require('./../assets/fonts/Adieu-Regular.woff');
const AdieuRegularWoff2 = require('./../assets/fonts/Adieu-Regular.woff2');
const AdieuRegularEOT = require('./../assets/fonts/Adieu-Regular.eot');

export const AdieuFonts = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=PT+Mono&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

    @font-face {
        font-family: 'Adieu Regular';
        src: local('Adieu Regular'), local('AdieuRegular'),
            url(${AdieuRegularWoff}) format('woff2'),
            url(${AdieuRegularWoff2}) format('woff2'),
            url(${AdieuRegularEOT}) format('eot');
        font-weight: 300;
        font-style: normal;
    }
`;
