import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import LoadingScreen from "../components/LoadingScreen";
import MomentThumb from "../components/MomentThumb";
import useSong from "../data/useSong";
import useMomentsForSong from "../data/useMomentsForSong";
import { SmallButton, Divider, TopLogo, SubHeader, BigButton, Paragraph, SongNameHeader, ArtistHeader } from "../components/ui";
import { useHistory, useParams } from "react-router";
import { useMusicPlayer } from "../components/MusicContext";
import SongCard from "../components/SongCard";

interface SongPageParams {
    songId: string;
}

const SongPage: React.FC = () => {
    const history = useHistory();
    const { songId } = useParams<SongPageParams>();
    const song = useSong(songId);
    const moments = useMomentsForSong(songId);
    const musicPlayer = useMusicPlayer();

    const [showBuy, setShowBuy] = useState(false);

    useEffect(() => {
        if (song?.previewUrl) {
            musicPlayer.setTrackUrl(song.previewUrl);
        }
    }, [musicPlayer, song?.previewUrl]);

    if (!song) {
        return <LoadingScreen />
    }

    const dataPoints = [
        ["NFTs left", "12"],
        ["Collectors", "988"],
        ["Moments added", "912"],
        ["Listing price", "$10"],
    ]

    return (
        <Container>
            <TopLogo />

            <Header>
                <SongNameHeader>{song.name}</SongNameHeader>
                <ArtistHeader>{song.artist.name}</ArtistHeader>
            </Header>

            <Divider />

            <SongData>
                <SongDataPerformance>
                    {dataPoints.map(([label, value]) => (
                        <SongDataItem key={label}>
                            <SongDataLabel>{label}</SongDataLabel>
                            <SongDataValue>{value}</SongDataValue>
                        </SongDataItem>
                    ))}

                    <SongDataItem style={{marginTop: 20}}>
                        <SongDataLabel>Release year</SongDataLabel>
                        <SongDataValue>2021</SongDataValue>
                    </SongDataItem>

                    <BuyButtonContainer>
                        <SmallButton onClick={() => setShowBuy(true)}>
                            Buy now
                        </SmallButton>
                    </BuyButtonContainer>
                </SongDataPerformance>

                <SongDataCard>
                    <SongCard
                        song={song}
                        size="small"
                    />
                </SongDataCard>
            </SongData>

            <Divider />

            <SubHeader>
                912 collectors have minted moments to this song
            </SubHeader>

            <MomentGrid>
                {moments && moments.map((moment) => (
                    <MomentItem
                        key={moment.id}
                        onClick={() => {
                            history.push(`/song/${songId}/moment/${moment.id}`);
                            musicPlayer.setTrackUrl(song.previewUrl);
                        }}
                    >
                        <MomentThumb
                            moment={moment}
                        />
                    </MomentItem>
                ))}
            </MomentGrid>

            {showBuy && (
                <BuyModalOverlay
                    onClick={() => setShowBuy(false)}
                />
            )}
            <BuyModal isVisible={showBuy}>
                <BuyModalContent>
                    <Paragraph>
                        <strong>What you get</strong>
                    </Paragraph>

                    <ReasonsList>
                        <ReasonsListItem>
                            A full streamable version of the original song in the highest quality
                        </ReasonsListItem>
                        <ReasonsListItem>
                            Add your own moment (image/video) to the collector hall-of-fame, forever alive on the blockchain
                        </ReasonsListItem>
                        <ReasonsListItem>
                            The official NFT collectible for this song. For you to keep, share online, or sell on any NFT marketplace
                        </ReasonsListItem>
                        <ReasonsListItem>
                            Connect the NFT to one of our partner platforms to show off your NFT, such as Twitter, Meta or Roblox
                        </ReasonsListItem>
                    </ReasonsList>

                    <BuyModalButton>
                        Buy now ($10)
                    </BuyModalButton>
                </BuyModalContent>
            </BuyModal>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Header = styled.div`
    text-align: center;
`

const SongData = styled.div`
    display: flex;
    flex-direction: row;
`

const BuyButtonContainer = styled.div`
    margin-top: 20px;
`

const SongDataPerformance = styled.div`
    width: 50%;
    font-family: ${props => props.theme.textFont};
    font-size: 12px;
    line-height: 18px;
    padding-right: 10px;
`

const SongDataCard = styled.div`
    width: 50%;
    padding-left: 10px;
`

const SongDataItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const SongDataLabel = styled.div`
    margin-right: 5px;

    &:after {
        content: ":";
    }
`

const SongDataValue = styled.div`
    font-weight: bold;
`


const MomentGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    max-width: 400px;
`

const MomentItem = styled.div`
    margin-bottom: 5px;
    width: calc(33% - 5px);
    margin-right: 5px;
    /* transition: all 0.1s; */
    cursor: pointer;

    &:nth-child(3n) {
        margin-right: 0;
    }

    /* &:hover {
        transform: scale(1.1);
    } */
`

const BuyModalOverlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

const BuyModal = styled.div<{ isVisible: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100vh;
    overflow: auto;
    border: 3px solid rgba(255, 255, 255, 0.5);
    border-radius: 41px;
    background: rgba(0, 0, 0, 0.9);
    padding: 30px;
    padding-top: 90px;
    font-family: ${props => props.theme.textFont};
    letter-spacing: -0.5px;

    ${props => !props.isVisible && css`
        opacity: 0;
        pointer-events: none;
    `}

    ${props => props.isVisible && css`
        animation: modalAnimation 0.3s ease-in-out;
    `}

    @keyframes modalAnimation {
        0% {
            opacity: 0;
            transform: scale(0.9) translateY(50px);
        }

        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
        }
    }
`

const BuyModalContent = styled.div`
    max-width: 320px;
    margin: 0 auto;
`

const ReasonsList = styled.ul`

`
const ReasonsListItem = styled.li`
    padding-bottom: 20px;
`

const BuyModalButton = styled(BigButton)`
    margin-left: auto;
    margin-right: auto;
    display: block;
`

export default SongPage;
