import { useEffect, useState } from "react";
import { DriveVideo, makeS3Video } from "../clients/DriveVideosClient";
import Moment from "../models/Moment";
import { testDB } from "./testDB";
import useDriveVideosClient from "./useDriveClient";

export default function useMomentsForSong(songId: string | undefined): Moment[] | undefined {
    const drive = useDriveVideosClient();

    const [moments, setMoments] = useState<Moment[] | undefined>(undefined);

    useEffect(() => {
        if (!drive || !songId) {
            return;
        }

        let moment = testDB.find(s => s.trackId === songId);
        if (!moment) {
            // Get random momment
            moment = testDB[Math.floor(Math.random() * testDB.length)];
        }
        const videoUrls = moment.fileNames || [];
        const videos = videoUrls.map(v => makeS3Video(v));
        setMoments(videos.map(video => driveVideoToMoment(video, songId)));
    }, [drive, songId]);

    return moments;
}

export function driveVideoToMoment(video: DriveVideo, songId: string): Moment {
    return {
        id: video.id,
        userId: "user1",
        songId: songId,
        coverImage: video.thumbUrl,
        mediaUrl: video.url,
        mediaType: "video"
    }
}
