import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Song from "../models/Song";
import SongCardId from "./SongCardId";
import fingerprintImage from "../assets/songbite-fingerprint.png";
import AlbumCover from "./AlbumCover";
import MomentPlayer from "./MomentPlayer";
import Moment from "../models/Moment";
import { getContrastTextColor } from "../data/getContrastTextColor";
import useSong from "../data/useSong";

interface Props {
    song: Song;
    moment?: Moment;
    animate?: false;
    size?: "small" | "large" | "vh";
    onClick?: (song: Song) => void;
}

const SongCard: React.FC<Props> = ({ song, moment, animate, size, onClick }) => {
    const [backgroundColor, setBackgroundColor] = useState<string>("");

    const contrastColor = useMemo(() => {
        return getContrastTextColor(backgroundColor)
    }, [backgroundColor]);

    const customCss = useMemo(() => {
        if (!backgroundColor) {
            return undefined;
        }
        return {
            backgroundColor: backgroundColor,
            color: contrastColor,
        }
    }, [backgroundColor, contrastColor]);

    return (
        <PerspectiveContainer onClick={() => onClick?.(song)}>
            <SpinAnimationContainer animate={!!(animate && moment)}>
                <Container size={size} style={customCss}>
                    <AlbumCoverContainer>
                        <AlbumCoverAnimator>
                            <AlbumCover
                                src={song.album.coverImage}
                                size="full"
                                imageStyle={"square"}
                                didGetColor={setBackgroundColor}
                            />
                        </AlbumCoverAnimator>
                    </AlbumCoverContainer>

                    <Name>{song.name}</Name>
                    <Artist>{song.artist.name}</Artist>
                    <Footer>
                        <SongCardId
                            id={30}
                            total={100}
                            color={contrastColor}
                        />

                        <Stamp>
                            SB - 261.31.100
                        </Stamp>

                        <Fingerprint
                            src={fingerprintImage}
                            style={{ filter: contrastColor == "white" ? "invert(1)" : undefined }}
                        />
                    </Footer>
                </Container>

                {moment && (
                    <MomentPreviewContainer>
                        <MomentPlayer
                            moment={moment}
                        />

                        {/* <div style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "red"
                        }}></div> */}
                    </MomentPreviewContainer>
                )}
            </SpinAnimationContainer>
        </PerspectiveContainer>
    )
}

interface MomentCardProps extends Omit<Omit<Props, "song">, "moment"> {
    song?: Song;
    moment: Moment;
}

export const MomentCard: React.FC<MomentCardProps> = (props) => {
    const song = useSong(props.moment.songId);

    if (!song) {
        return <></>
    }

    return (
        <SongCard
            {...props}
            song={song}
        />
    )
}

interface SizeProps {
    size?: "small" | "large" | "vh";
}

const PerspectiveContainer = styled.div`
    perspective: 600px;
`

const SpinAnimationContainer = styled.div<{ animate?: false }>`
    transform-style: preserve-3d;
	animation: rotateAnimation2 2s ease-in forwards;
    backface-visibility: hidden;

    ${props => props.animate === false && css`
        animation: none;
    `}

    @keyframes rotateAnimation2 {
        0% {
            transform: rotateY(0);
        }
        100% {
            transform: rotateY(180deg);
        }
    }

`

const Container = styled.div<SizeProps>`
    background: rgb(145, 125, 99);
    color: black;
    padding: 1.5em;
    padding-bottom: 8px;
    border-radius: 4px;
    position: relative;
    z-index: 2;
    text-align: left;
    font-size: 16px;
    width: 250px;

    ${props => props.size === "small" && css`
        width: 150px;
        aspect-ratio: 72%;
        font-size: 8px;
    `}

    ${props => props.size === "vh" && css`
        /* height: 50vh; */
        /* aspect-ratio: 72%; */
    `}
`

// https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/perspective()
// const BoxBack = styled.div`
// `
// const BoxLeft = styled.div`
// `
// const BoxRight = styled.div`
// `
// const BoxTop = styled.div`
// `
// const BoxBottom = styled.div`
// `

const AlbumCoverContainer = styled.div`
    margin-bottom: 1em;
    position: relative;
`

const AlbumCoverAnimator = styled.div`
`

const MomentPreviewContainer = styled.div`
    position: absolute;
    top: 8px;
    bottom: 8px;
    right: 8px;
    left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transform: rotateY(180deg);
    backface-visibility: hidden;
`

const Name = styled.div`
    font-size: 1.5em;
    font-family: Adieu Regular;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Artist = styled.div`
    font-size: 1.2em;
    font-family: Adieu Regular;
`

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 2em;
`

const Fingerprint = styled.img`
    width: 3.5em !important;
    margin-left: auto;
    user-select: none;
`

const Stamp = styled.div`
    font-family: ${props => props.theme.wackyFont};
    font-size: 0.5em;
    padding-left: 2em;
    padding-bottom: 0.2em;
`

export default SongCard;
