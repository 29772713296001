import React, { useCallback, useEffect } from "react";
import styled, { css } from "styled-components";
import Song from "../models/Song";
import AlbumCover from "../components/AlbumCover";
import { useMusicPlayer } from "./MusicContext";

interface Props {
    song: Song;
    showMomentCount?: boolean;
    width?: string;
    didClick?: (song: Song) => void;
}

const SongResult: React.FC<Props> = ({ song, width, showMomentCount, didClick }) => {
    const musicPlayer = useMusicPlayer();
    const isSpinning = musicPlayer.player.isPlaying && musicPlayer.player.trackUrl === song.previewUrl;

    const onClick = useCallback((e: React.MouseEvent) => {
        if (didClick) {
            e.preventDefault();
            didClick(song);
        }
    }, [didClick, song]);

    return (
        <Container onClick={didClick ? onClick : undefined} width={width}>
            <AlbumCoverContainer>
                <AlbumCover
                    src={song.album.coverImage}
                    imageStyle="circle"
                    size="small"
                    spin={isSpinning}
                />
            </AlbumCoverContainer>
            <NameContainer>
                <NameText>
                    {song.name}
                </NameText>
                <AlbumText>
                    {song.artist.name}
                </AlbumText>
                {showMomentCount && (
                    <MomentsText>
                        {calculateLeft(song)}
                    </MomentsText>
                )}
            </NameContainer>
        </Container>
    )
}

function calculateLeft(song: Song): string {
    if (song.momentsLeft <= 0) {
        return "Nothing left"
    } else {
        return `${song.momentsLeft} left`
    }
}

const NameContainer = styled.div`
    text-align: left;
`

const AlbumCoverContainer = styled.div`
    margin-right: 25px;
    transition: transform 0.1s ease;
`

const MomentsText = styled.div`
    color: green;
    margin-top: 4px;
    font-size: 12px;
`

const NameText = styled.div`
    font-size: 20px;
    font-family: ${props => props.theme.headerFont};
`

const AlbumText = styled.div`
    font-size: 16px;
    font-family: ${props => props.theme.headerFont};
`

const Container = styled.div<{width?: string}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    width: ${props => props.width ? props.width : "100%"};
    max-width: 400px;
    position: relative;
    z-index: 10;

    ${props => props.onClick && css`
        cursor: pointer;

        &:hover {
            ${AlbumCoverContainer} {
                transform: scale(1.1);
            }
        }
    `}
`

export default SongResult;
