export function getContrastTextColor(
    bgColor: string,
    lightColor: string = "white",
    darkColor: string = "black"
): string {
    if (!bgColor) {
        return darkColor;
    }
    const [r, g, b] = parseColor(bgColor);
    if (r * 0.299 + g * 0.587 + b * 0.114 > 150) {
        return darkColor;
    } else {
        return lightColor;
    }
}

function parseColor(color: string): [number, number, number] {
    // #rrggbb
    if (color.charAt(0) === "#") {
        const str = color.charAt(0) === "#" ? color.substring(1, 7) : color;
        const r = parseInt(str.substring(0, 2), 16); // hexToR
        const g = parseInt(str.substring(2, 4), 16); // hexToG
        const b = parseInt(str.substring(4, 6), 16); // hexToB
        return [r, g, b];
    // rgb(r, g, b)
    } else {
        const pieces = color.split("(")[1].split(")")[0].split(",");
        return pieces.map(x => parseInt(x, 10)) as any;
    }
}
