import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { BigHeader, Paragraph, TopLogo } from "../components/ui";
import SongResult from "../components/SongResult";
import MomentGrid from "../components/MomentGrid";
import { useMusicPlayer } from "../components/MusicContext";
import LoadingIndicator from "../components/LoadingIndicator";
import useSearchResults from "../data/useSearchResults";
import { useHistory } from "react-router";
import Song from "../models/Song";
import LoadingPage from "./LoadingPage";
import { getRandomMoments } from "../data/testDB";

let globalShouldSeeLoading = true;

const SearchPage: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, isLoading] = useSearchResults(searchQuery);
    const [isFocused, setIsFocused] = useState(false);

    const history = useHistory();
    const musicPlayer = useMusicPlayer();

    const gotoSong = useCallback((song: Song) => {
        history.push(`/song/${song.id}`);
        musicPlayer.setTrackUrl(song.previewUrl);
    }, [history, musicPlayer]);

    useEffect(() => {
        musicPlayer.setTrackUrl(undefined);
    }, [musicPlayer]);

    const [showLoadingScreen, setShowLoadingScreen] = useState(globalShouldSeeLoading);

    const randomMoments = useMemo(() => {
        return getRandomMoments(6);
    }, []);

    const showResults = !!(searchQuery || isFocused) && !showLoadingScreen;

    const hideLoading = () => {
        setShowLoadingScreen(false);
        globalShouldSeeLoading = false;
    }

    return (
        <Container>
            <LoadingPageContainer
                isHidden={!showLoadingScreen}
                onClick={hideLoading}
            >
                <LoadingPage />
            </LoadingPageContainer>

            <TopLogo noBack />

            <TextContainer isHidden={showResults}>
                <BigHeader>
                    Find your song.
                </BigHeader>

                <BreadText>
                    Search for any song you love or choose one of the NFTs below to get started.
                </BreadText>
            </TextContainer>

            <SearchInputContainer>
                <SearchInput
                    value={searchQuery}
                    placeholder="Search for your song"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={() => {
                        setIsFocused(true)
                        window.scrollTo(0, 0);
                    }}
                    onBlur={() => setIsFocused(false)}
                />
                <SearchInputIcon />
            </SearchInputContainer>

            {!showResults && randomMoments && (
                <LatestContainer>
                    <LatestHeader>Popular songs</LatestHeader>

                    <MomentGrid
                        moments={randomMoments}
                    />
                </LatestContainer>
            )}

            {showResults && !isLoading && (
                <SearchResults>
                    {searchResults.map((song, index) => (
                        <SongResult
                            key={index}
                            song={song}
                            didClick={gotoSong}
                        />
                    ))}
                </SearchResults>
            )}

            {showResults && isLoading && (
                <SearchResults>
                    <LoadingContainer>
                        <LoadingIndicator />
                    </LoadingContainer>
                </SearchResults>
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

const LoadingPageContainer = styled.div<{ isHidden: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all 0.7s ease-out;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: ${props => props.theme.backgroundColor};
    overflow: auto;

    ${props => props.isHidden && css`
        opacity: 0;
        transform: scale(1.5);
        pointer-events: none;
    `}
`

interface TextContainerProps {
    isHidden: boolean;
}

const TextContainer = styled.div<TextContainerProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
    transition: all 0.3s;
    // Use height instead of padding for animation purposes
    height: 200px;

    ${(props) => props.isHidden && css`
        height: 0;
        opacity: 0;
    `}
`

const SearchInputContainer = styled.div`
    display: inline-flex;
    position: relative;
    width: 320px;
`

const BreadText = styled(Paragraph)`
    width: 275px;
    padding-bottom: 30px;
    padding-top: 10px;
`

const SearchInputIcon = styled.img.attrs({
    src: require("../assets/magnifyingglass@2x.png"),
})`
    width: 16px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
`

const SearchInput = styled.input.attrs({
    type: "search"
})`
    background: rgba(118, 118, 128, 0.24);
    mix-blend-mode: normal;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    padding: 7px 8px;
    padding-left: 36px;
    font-size: 16px;
    height: 48px;
    width: 100%;
    font-family: ${props => props.theme.textFont};
    letter-spacing: -0.5px;

    ::placeholder {
        color: ${props => props.theme.textColor};
        text-align: center;
    }

    &:focus {
        outline: none;
    }
`

const SearchResults = styled.div`
    width: 100%;
    max-width: 400px;
    padding-top: 30px;
    padding-bottom: 30px;
`

const LatestContainer = styled.div`
    padding-top: 70px;
    padding-bottom: 70px;
    width: 100%;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
`

const LatestHeader = styled(Paragraph)`
    margin-bottom: 30px;
`

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
`

export default SearchPage;
