import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SongResult from "../components/SongResult";
import LoadingScreen from "../components/LoadingScreen";
import MomentViewer from "../components/MomentViewer";
import useSong from "../data/useSong";
import useMomentsForSong from "../data/useMomentsForSong";
import { ArtistHeader, Divider, Paragraph, SongNameHeader, TopLogo } from "../components/ui";
import { useHistory, useParams } from "react-router";
import { useMusicPlayer } from "../components/MusicContext";
import SongCard from "../components/SongCard";

interface MomentPageParams {
    songId: string;
    momentId: string;
}

const MomentPage: React.FC = () => {
    const { songId } = useParams<MomentPageParams>();
    const song = useSong(songId);
    const moments = useMomentsForSong(songId);
    const musicPlayer = useMusicPlayer();
    const scrollerRef = useRef<HTMLDivElement>(null);
    const history = useHistory();

    const scrollRef = useRef<number>(0);
    const [currentScrolled, setCurrentScrolled] = useState<number>(0);

    useEffect(() => {
        if (song?.previewUrl) {
            musicPlayer.setTrackUrl(song.previewUrl);
        }
    }, [musicPlayer, song?.previewUrl]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentScrolled(scrollRef.current + 1);
        }, 5000);

        return () => {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        if (!scrollerRef.current) {
            return;
        }

        scrollerRef.current.scrollTo({
            top: currentScrolled * scrollerRef.current.offsetHeight,
            // behavior: "smooth"
        })
    }, [currentScrolled]);

    const didScrollMomentContainer = useCallback((e: React.UIEvent<HTMLDivElement>) => {
        const target = e.currentTarget;
        const height = target.offsetHeight;
        scrollRef.current = Math.floor(target.scrollTop / height);
    }, []);

    if (!song) {
        return <LoadingScreen />
    }

    return (
        <Container>
            <TopLogo />

            <NowPlayingHeader>
                <NowPlayingTexts>
                    <Paragraph>Now playing:</Paragraph>
                    <LimitedSongNameHeader>{song.name}</LimitedSongNameHeader>
                    <LimitedArtistHeader>{song.artist.name}</LimitedArtistHeader>
                </NowPlayingTexts>

                <CardContainer>
                    <SongCard
                        song={song}
                        size="small"
                        onClick={() => {
                            history.push(`/song/${song.id}`)
                            musicPlayer.setTrackUrl(song.previewUrl);
                        }}
                    />
                </CardContainer>
            </NowPlayingHeader>

            <Divider />

            <MomentScroller onScroll={didScrollMomentContainer} ref={scrollerRef}>
                <MomentScrollerInner>
                    {moments && moments.map((moment, index) => (
                        <MomentScrollerPage key={moment.id}>
                            <MomentViewer
                                momentId={moment.id}
                                showSong={false}
                                loadVideo={currentScrolled === index || currentScrolled-1 == index || currentScrolled+1 == index}
                            />
                        </MomentScrollerPage>
                    ))}
                </MomentScrollerInner>
            </MomentScroller>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const NowPlayingHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const NowPlayingTexts = styled.div`
    width: 200px;
    margin-right: 15px;
`
const CardContainer = styled.div`
    margin-bottom: -100px;
    position: relative;
    z-index: 100;
`

const LimitedSongNameHeader = styled(SongNameHeader)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const LimitedArtistHeader = styled(ArtistHeader)`
    white-space: no-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const MomentScroller = styled.div`
    overflow: auto;
    height: calc(100vh - 250px);
`
const MomentScrollerInner = styled.div`
`
const MomentScrollerPage = styled.div`
    width: 100vw;
    height: calc(100vh - 250px);
`

export default MomentPage;
