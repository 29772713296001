import { Route } from "react-router-dom";
import {
    IonApp,
    setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import SearchPage from "./pages/SearchPage";
import SongPage from "./pages/SongPage";
import MomentPage from "./pages/MomentPage";
import UserPage from "./pages/UserPage";
import ApiContext, { ApiContextType } from "./data/ApiContext";
import { MusicGlobal } from "./components/MusicContext";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { AdieuFonts } from "./theme/fonts";
import styled, { ThemeProvider } from "styled-components";
import themes from "./theme/styled";

import Parse from "parse";
import { useEffect, useState } from "react";
import { SpotifyClient } from "./clients/SpotifyClient";
import { DriveVideosClient } from "./clients/DriveVideosClient";
import SharePage from "./pages/SharePage";

// Your Parse initialization configuration goes here
const PARSE_APPLICATION_ID = "hFOtYv7EWpKenTGwZ4gVKx1SLJXH10ILuulovAUZ";
const PARSE_HOST_URL = "https://parseapi.back4app.com/";
const PARSE_JAVASCRIPT_KEY = "9WHncVX32lX6IgZnRS8iw4vPavSW6FbVapPOFYzV";
Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

setupIonicReact();

const App: React.FC = () => {
    const [apiClient, setApiClient] = useState<ApiContextType>({});

    useEffect(() => {
        const doer = async () => {
            const spotifyClient = new SpotifyClient();
            await spotifyClient.login();

            const driveVideosClient = new DriveVideosClient();

            setApiClient({
                spotifyClient,
                driveVideosClient,
            });
        }

        doer();
    }, []);

    return (
        <IonApp>
            <ThemeProvider theme={themes.default}>
                <ApiContext.Provider value={apiClient}>
                    <MusicGlobal>
                        <AdieuFonts />
                        <IonReactRouter>
                            <Route exact path="/">
                                <Layout>
                                    <SearchPage />
                                </Layout>
                            </Route>
                            <Route exact path="/song/:songId">
                                <Layout>
                                    <SongPage />
                                </Layout>
                            </Route>
                            <Route exact path="/song/:songId/moment/:momentId">
                                <Layout>
                                    <MomentPage />
                                </Layout>
                            </Route>
                            <Route exact path="/user/:userId">
                                <Layout>
                                    <UserPage />
                                </Layout>
                            </Route>
                            <Route exact path="/share">
                                <Layout>
                                    <SharePage />
                                </Layout>
                            </Route>
                        </IonReactRouter>
                    </MusicGlobal>
                </ApiContext.Provider>
            </ThemeProvider>
        </IonApp>
    )
}

const Layout = styled.div`
    background: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.textColor};
    font-family: ${props => props.theme.primaryFont};
    height: 100vh;
    overflow: auto;
`

export default App;
