import styled from "styled-components";
import React from "react";

interface Props {
    id: number;
    total: number;
    color?: string;
}

const SongCardId: React.FC<Props> = ({ id, total, color }) => {
    return (
        <Container>
            <Id style={{ borderColor: color }}>{id}</Id>
            <Total>{total}</Total>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    font-family: ${props => props.theme.wackyFont};
    font-size: 0.75em;
    justify-content: center;
    align-items: center;
`

const Id = styled.div`
    border: 0.5px solid ${props => props.theme.backgroundColor};
    border-radius: 10px;
    padding: 1px 4px;
`

const Total = styled.div`

`

export default SongCardId;
