import React from "react";
import styled from "styled-components";
import { BigHeader, Paragraph } from "../components/ui";
import useSong from "../data/useSong";
import SongCard from "../components/SongCard";
import { Link } from "react-router-dom";
import useMomentsForSong from "../data/useMomentsForSong";
import { globalState } from "../data/globalState";

const LoadingPage: React.FC = () => {
    const song = useSong(globalState.loadingPageSongId);
    const moments = useMomentsForSong(globalState.loadingPageSongId);

    return (
        <Container>
            <LoadingBigHeader>
                <Link to="/">
                    Build your collection<br />
                    Add your <em>moments</em>
                </Link>
            </LoadingBigHeader>

            <BreadText>
                1,000 unique official NFTs per song
            </BreadText>

            <CardPreviewImage />

            <LogoImage />

            <LoadingText>
                <strong>Tap on screen to enter</strong>
            </LoadingText>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const LoadingBigHeader = styled(BigHeader)`
    margin-bottom: 10px;
    padding-top: 40px;
`

const BreadText = styled(Paragraph)`
    margin-bottom: 10px;
    padding-top: 40px;
`

const LoadingText = styled(Paragraph)`
    margin-bottom: 10px;
    padding-top: 10px;
`

const LogoImage = styled.img.attrs({
    src: require("../assets/logo-small.png"),
})`
    margin-top: 32px;
    width: 60px;
    user-select: none;
`

const CardPreviewImage = styled.img.attrs({
    src: require("../assets/loading-bg.png"),
})`
    margin-top: 32px;
    width: 100%;
    max-width: 400px;
    user-select: none;
`

export default LoadingPage;
