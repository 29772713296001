import styled from "styled-components"
import React from "react";
import { MomentCard } from "./SongCard";
import Moment from "../models/Moment";
import { useHistory } from "react-router";
import { useMusicPlayer } from "./MusicContext";

interface MomentGridProps {
    moments: Moment[];
}

const MomentGrid: React.FC<MomentGridProps> = ({ moments }) => {
    const history = useHistory();
    const musicPlayer = useMusicPlayer();

    return (
        <Container>
            {moments.map((moment, index) => (
                <ItemContainer key={index}>
                    <MomentCard
                        moment={moment}
                        size="small"
                        animate={false}
                        onClick={(song) => {
                            history.push(`/song/${moment.songId}`)
                            musicPlayer.setTrackUrl(song.previewUrl);
                        }}
                    />
                </ItemContainer>
            ))}
        </Container>
    )
}

const Container = styled.div`
    display: grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    column-gap: 0;
    grid-auto-rows: minmax(max-content, auto);
    justify-items: center;
    /* column-gap: 20px;
    width: 100%;
    row-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    grid-auto-flow: column;
    justify-items: center; */

`

const ItemContainer = styled.div`
    width: 150px;
    overflow: hidden;
`

export default MomentGrid;
