import { testDB } from "../data/testDB";

// const ids = [
//     '1xjsJf9EZ7UUuYyIigRNafnH8SLWSHGS0',
//     '18QodXqbWXDshJJSt86cc5fPQt76esGWG',
//     '1HHZmpu9Y4_N7nt6CslxAs3yxjPbnxzPt',
//     '1A_7YHLprkQiX0pU2JShe1zrupgaTIZDk',
//     '1TLT5b20bXhVKMnlqKiChGmh30zHLgE-a',
//     '1_LY1EwukPBy5y68t8kYN1gpn758z8DCa',
//     '1w2fnFZiJ2B0R8bHBIYBs9kA2yTwHAnnW',
//     '1Zn4bojQZzo92eairCKmsoIDImGlb8_Ok',
//     '1QLgdMKC5-M3Gk46J6StFwY5DVOHQLI52',
//     '1P_mhonxcw8HATn7OKYVl-fYr6kess-Ul',
//     '1SDlJcUJzOOG69fXKsPBmr-gdzfk01X8S',
//     '1xKNstsI4eFkqJJVcDpGhFPiCWq2cAEqX',
//     '1lhNl0kkm9GPi1PqO4pYBEY_h1R9diW7D',
//     '1T8O_Dq1fRr4Wg_AHCwwLGsohEC939Ud6',
//     '1gAEbsRQE13kdlRn9bgYC5JvtR7Zg_Ri8',
//     '1nyY4g4cdWkg-ajsTKpBIkaV11TS3Bnnm',
//     '1g7WMEtJzAAv0PDCrLX_dQgtF0eR04Qyo',
//     '1xrkZ60dPZGov-GMhFBrxxdVhKZc9d7fk', // 9461
//     '1mgdxVBiTz9yzrnS47YkKd-FANtnYBIkH',
//     '19ZRMFuh1wSKF3p4jil0QWLjPckjwRdhT',
//     '1wfKrPedBaTePD73mGXSw71Vl-3y3dcaI',
//     '1RaCzw8MjljFiaH1Pr0FRP-aA8m_SiFQk',
//     '1riXL0NF9Dsq8smYm8iM_JKhxUhUFxHbg',
//     '1YA7PylemyxAjvvMMSLHmLOVW422Li0MP',
//     '1uR5T4KjzvuHAJvoWFYpERXuFQ-w8_Mrt',
//     '1iT-mXNbPqfuyuxeAwAMysFist0SHJySY',
//     '1a6sADHugcyqX7YH9v3Ko79d19IPMbbBH',
//     '1YfilOA8PkLaCHytIr6UJlXl5Z4CWuMYL',
//     '1q5c0BjCLIl9gUMMfJtF2xVu5iq0ReEBy',
//     '1ZGTvwyakv6hgQ5vGVaoMHt_755E1eDeZ',
//     '1KwtOdKpzNfjpUUQB3m_4wXQo_Iazy17e',
//     '11OVAm84KXXO6C_leOADngqsRH7f3QJh9',
//     '1mb6SoPdBlcjX6PChblNVLCPFSqFO3K4x',
//     '1G9m12fnEXREDFmdQY6wOWB6Wg77vtiuC',
//     '1HO4CHR6uX60-blH7xMPu-JAcOerLkMdj',
//     '1FJ1on0OLRjIymhOO8oNsI4TDNi26tIrC',
//     '1VJuyP8V7lnwBIEwt5coguSvCl_Ja0cTc',
//     '1VJuyP8V7lnwBIEwt5coguSvCl_Ja0cTc',
//     '1ObeSQUgvrq77cUxewpy_LG_7veBdpRlW',
//     '1bzBN3AsSmdwcwuV22HyWl2-2VLSkeEl0',
//     '16p2ERjXGVFidyJaVxzlu6ggdoQdwmPN4',
//     '1VZKByboaXNQKCBleu6F2xqV8SgmPuSD2',
//     '11RLof2VBlW8TunBuT1Ymt--em_LdmRPk',
//     '1oLLu2zg037Llo4kXh3ilYXQOODRNXyMx',
//     '1nPbjuyu96ZpTl3TXBNZhuck6iPp4wVzS',
//     '1FySXuWUv9efdm-2llHJ44bC5j04VRRxJ',
//     '1VcgEd0_Ajob8efXGD70UU_GtV3vvptCk',
//     '1Ha-k3CvTuRBq4RatA-Pwa2F10ynfeo-W',
//     '10Efwm3YVhAL06FG_0UXGhyhDbfTqp1Yn',
//     '1jblp6o982k1nOJ6rj5lkncKn9_oeekVm',
//     '1pb60AA8BJshEdwuYLWkbp34JVpoMHHz5',
//     '1XqK3RZaECPl_dc-C4ac9zrwORML-iIkz',
// ]

// const ids = [
//     "1A_7YHLprkQiX0pU2JShe1zrupgaTIZDk",
//     "1gAEbsRQE13kdlRn9bgYC5JvtR7Zg_Ri8",
//     "1lhNl0kkm9GPi1PqO4pYBEY_h1R9diW7D",
//     "1xKNstsI4eFkqJJVcDpGhFPiCWq2cAEqX",
//     "1_LY1EwukPBy5y68t8kYN1gpn758z8DCa",
//     // "1xjsJf9EZ7UUuYyIigRNafnH8SLWSHGS0", // laggy
//     "1T8O_Dq1fRr4Wg_AHCwwLGsohEC939Ud6",
//     "1QLgdMKC5-M3Gk46J6StFwY5DVOHQLI52",
//     "1P_mhonxcw8HATn7OKYVl-fYr6kess-Ul",
//     "1TLT5b20bXhVKMnlqKiChGmh30zHLgE-a",
//     "1w2fnFZiJ2B0R8bHBIYBs9kA2yTwHAnnW",
//     "1HHZmpu9Y4_N7nt6CslxAs3yxjPbnxzPt",
//     "18QodXqbWXDshJJSt86cc5fPQt76esGWG",
//     "1Zn4bojQZzo92eairCKmsoIDImGlb8_Ok",
//     "1SDlJcUJzOOG69fXKsPBmr-gdzfk01X8S",
// ]

const ids = testDB.map(a => a.momentUrls).flat();

export interface DriveVideo {
    id: string;
    url: string;
    thumbUrl: string;
}

export class DriveVideosClient {

    random = (size = 10): DriveVideo[] => {
        let myIds: string[] = [];
        for (let i = 0; i <= size; i++) {
            const randKey = Math.floor((Math.random()*ids.length));
            myIds.push(ids[randKey]);
        }
        return arrayUnique(myIds).map(id => makeDriveVideo(id));
    }

    find = (id: string): Promise<DriveVideo> => {
        // return Promise.resolve(makeDriveVideo(id));
        return Promise.resolve(makeS3Video(id));
    }
}

function arrayUnique<T>(arr: T[]): T[] {
    return arr.filter((x, i) => arr.indexOf(x) === i);
}

export function makeDriveVideo(id: string): DriveVideo {
    return {
        id,
        url: `https://drive.google.com/uc?id=${id}`,
        thumbUrl: `https://drive.google.com/thumbnail?sz=w320&id=${id}`
        // url: `https://lala-moments.s3.eu-west-1.amazonaws.com/${id}.mp4`,
        // thumbUrl: `https://lala-moments.s3.eu-west-1.amazonaws.com/thumbs/${id}.png`,
    }
}

export function makeS3Video(id: string): DriveVideo {
    let a = id.replace(/---/g, "/");
    if (!a.includes("/")) {
        a = `compressed/super_compressed/${id}`;
    }
    return {
        id: a.replace(/\//g, "---"),
        url: `https://lala-moments.s3.eu-west-1.amazonaws.com/${a.replace(/---/g, "/")}`,
        thumbUrl: "",
        // url: `https://lala-moments.s3.eu-west-1.amazonaws.com/${id}.mp4`,
        // thumbUrl: `https://lala-moments.s3.eu-west-1.amazonaws.com/thumbs/${id}.png`,
    }
}
