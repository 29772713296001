import { createContext } from "react";
import { SpotifyClient } from "../clients/SpotifyClient";
import { DriveVideosClient } from "../clients/DriveVideosClient";

export interface ApiContextType {
    spotifyClient?: SpotifyClient;
    driveVideosClient?: DriveVideosClient;
}

const ApiContext = createContext<ApiContextType>({
});

export default ApiContext;
