import React from "react";
import styled from "styled-components";
import { EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SongCard, { MomentCard } from "../components/SongCard";
import useSong from "../data/useSong";
import { useHistory } from "react-router";
import { useMusicPlayer } from "./MusicContext";
import Moment from "../models/Moment";

interface Props {
    moments: Moment[];
}

const MomentsCarousel: React.FC<Props> = ({ moments }) => {
    const history = useHistory();
    const musicPlayer = useMusicPlayer();

    return (
        <Container>
            <Swiper
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate: 60,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                effect={"coverflow"}
                modules={[EffectCoverflow]}
            >
                {moments && moments.map(moment => (
                    <SwiperSlide key={moment.id}>
                        <MomentCard
                            animate={false}
                            moment={moment}
                            size="vh"
                            onClick={(song) => {
                                history.push(`/song/${moment.songId}`);
                                musicPlayer.setTrackUrl(song.previewUrl);
                            }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    overflow: hidden;

    .swiper {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 50px;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;

        width: 256px !important;
        margin-bottom: -40px;
        /* height: 40vh !important; */
        /* width: 50%;
        height: 50%; */
    }

    .swiper-slide img {
        display: block;
        width: 100%;
    }
`;

export default MomentsCarousel;
