import React, { useEffect, useRef } from "react";
// @ts-ignore
import { useVisibilityHook } from "react-observer-api";
import styled from "styled-components";
import Moment from "../models/Moment";

interface Props {
    moment: Moment;
    loadVideo?: boolean;
}

const MomentPlayer: React.FC<Props> = ({ loadVideo, moment }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const { setElement, isVisible } = useVisibilityHook({
        threshold: 0,
        rootMargin: "300px"
    });

    return (
        <Container ref={setElement}>
            {(isVisible || loadVideo) && (
                <MomentVideo
                    key={moment.id}
                    controls={false}
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload="auto"
                    ref={(el) => {
                        try {
                            el?.play()
                        } catch (e) {}
                    }}
                >
                    <source src={moment.mediaUrl} type="video/mp4" />
                </MomentVideo>
            )}
        </Container>
    )
}

const Container = styled.div`
    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;
    background: black;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`

const MomentVideo = styled.video`
    height: 100%;
    width: 100%;
    object-fit: cover;
    animation: fadeInAnimation 0.2s;

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

export default MomentPlayer;
