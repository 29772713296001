import { makeS3Video } from "../clients/DriveVideosClient";
import Moment from "../models/Moment";
import { testDB } from "./testDB";
import { driveVideoToMoment } from "./useMomentsForSong";

export function useUsersMoments(): Moment[] {
    const songs = [
        "First Aid Kit",
        "M83",
        "Mac Miller",
        "Theophilus London",
        "Labrinth",
        "Arcade Fire",
        "Bruce Springsteen",
    ]

    return songs.map(song => {
        const res = testDB.find(t => t.artistName.toLowerCase() === song.toLowerCase());
        if (!res) {
            console.log("song not found", song)
        }
        return res!;
    })
        .map((p) => {
            const videoUrl = p.fileNames.sort(() => Math.random() - 0.5)[0];
            return driveVideoToMoment(makeS3Video(videoUrl), p.trackId);
        });
}
