import React, { useState, useMemo } from "react";
import styled, { css } from "styled-components";
import useMoment from "../data/useMoment";
import useSong from "../data/useSong";
import SongResult from "../components/SongResult";
import MomentPlayer from "./MomentPlayer";
import { getRandomTwitterUser } from "../data/testDB";

interface Props {
    momentId: string;
    showSong?: false;
    loadVideo?: boolean;
}

const MomentViewer: React.FC<Props> = ({ momentId, showSong, loadVideo }) => {
    const moment = useMoment(momentId);
    const song = useSong(moment?.songId);
    const [likes, setLikes] = useState<number>(Math.floor(Math.random() * 1000 + 500));

    const twitterUser = useMemo(() => getRandomTwitterUser(), []);

    if (!moment || !song) {
        return null;
    }

    return (
        <Container>
            <MomentContainer>
                <MomentPlayer
                    moment={moment}
                    loadVideo={loadVideo}
                />

                <MomentUserContainer>
                    <UserAvatar
                        src={twitterUser.profileImage}
                    />
                    <UserProfileName>
                        {twitterUser.name}
                    </UserProfileName>
                </MomentUserContainer>

                <LikeContainer>
                    <LikeButton
                        onClick={() => setLikes((likes) => likes + 1)}
                    />
                    <LikeLabel>
                        {likes}
                    </LikeLabel>
                </LikeContainer>

                {showSong === false ? null : (
                    <SongResultContainer>
                        <SongResult
                            song={song}
                            width="auto"
                            showMomentCount
                        />
                    </SongResultContainer>
                )}
            </MomentContainer>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    height: 100%;
`

const MomentContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const MomentUserContainer = styled.div<{ atBottom?: boolean }>`
    position: absolute;
    top: 30px;
    left: 30px;
    text-align: left;

    ${props => props.atBottom && css`
        top: auto;
        bottom: 30px;
    `}
`

const UserAvatar = styled.img`
    border-radius: 100px;
    width: 40px;
    /* box-shadow: 0 0 10px black; */
`

const UserProfileName = styled.div`
    color: white;
`

const MomentPosted = styled.div`
    color: #AAB2B7;
    font-size: 12px;
`

const SongResultContainer = styled.div`
    background: rgb(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LikeContainer = styled.div`
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const LikeButton = styled.img.attrs({
    src: require("../assets/heart-icon.png")
})`
    width: 30px;
    margin-right: 10px;

    &:active {
        animation-name: bounce;
        animation-duration: 0.2s;
    }

    @keyframes bounce{
        0%{
            transform: scale(1);
        }
        100%{
            transform: scale(1.1);
        }
    }
`

const LikeLabel = styled.div`
    color: black
`

export default MomentViewer;
