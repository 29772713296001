import { useEffect, useState } from "react";
import Song, { spotifyTrackToSong } from "../models/Song";
import useSpotifyClient from "./useSpotifyClient";

export default function useSearchResults(searchQuery: string): [Song[], boolean] {
    const spotifyClient = useSpotifyClient();
    const [searchResults, setSearchResults] = useState<Song[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);

        if (!spotifyClient) {
            return;
        }

        if (!searchQuery) {
            setIsLoading(false);
            setSearchResults([]);
            return;
        }

        let isCancelled = false;

        const doer = async () => {
            // Debounce
            await timeout(300);

            const results = await spotifyClient.searchTracks(searchQuery);

            if (isCancelled) {
                return;
            }

            setIsLoading(false);
            setSearchResults(results.map(spotifyTrackToSong));
        }

        doer();

        return () => {
            isCancelled = true;
        }
    }, [searchQuery, spotifyClient]);

    return [searchResults, isLoading];
}

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
