import { useEffect, useState } from "react";
import Moment from "../models/Moment";
import { getRandomSong, testDB } from "./testDB";
import useDriveVideosClient from "./useDriveClient";
import { driveVideoToMoment } from "./useMomentsForSong";

export default function useMoment(momentId: string | undefined, songId?: string): Moment | undefined {
    const driveClient = useDriveVideosClient();

    const [moment, setMoment] = useState<Moment | undefined>(undefined);

    useEffect(() => {
        if (!momentId || !driveClient) {
            return;
        }

        let changed = false;

        const doer = async () => {
            let song = testDB.find(s => s.trackId === songId);
            if (!song) {
                song = getRandomSong();
            }
            const video = await driveClient.find(momentId);
            if (changed) {
                return;
            }
            setMoment(driveVideoToMoment(video, song.trackId));
        }

        doer();

        return () => {
            changed = true;
        }
    }, [momentId, driveClient, songId]);

    return moment;
}
