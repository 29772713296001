import React, { useEffect } from "react";
import styled from "styled-components";
import { BigHeader, SubHeader, TopLogo } from "../components/ui";
import "swiper/css";
// import "swiper/css/effect-coverflow";
import "swiper/css/effect-cards";
import MomentsCarousel from "../components/MomentsCarousel";
import { useMusicPlayer } from "../components/MusicContext";
import { useHistory } from "react-router";
import { useUsersMoments } from "../data/useUsersMoments";

const UserPage: React.FC = () => {
    // const moments = useMomentsForSong(globalState.userPageSongId);
    const moments = useUsersMoments();
    const history = useHistory();

    const musicPlayer = useMusicPlayer();

    useEffect(() => {
        musicPlayer.setTrackUrl(undefined);
    }, [musicPlayer]);

    return (
        <Container>
            <TopLogo />

            <HeaderSection>
                <BigHeader>
                    Your collection
                </BigHeader>

                <SubHeader>
                    Click on a Songbite to add a moment, share on other platforms or sell on the marketplace.
                </SubHeader>
            </HeaderSection>

            <MomentsContainer>
                <MomentsCarousel
                    moments={moments || []}
                />
            </MomentsContainer>

            <ShareButton>
                <ShareIcon onClick={() => history.push("/share") } />
            </ShareButton>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const HeaderSection = styled.div`
    text-align: center;
`

const MomentsContainer = styled.div`
    width: 100%;
    overflow: hidden;
`

const ShareButton = styled.div`
    margin-top: 50px;
    margin-bottom: 100px;
`

const ShareIcon = styled.img.attrs({
    src: require("../assets/share.and.arrow.up.png"),
})`
    width: 26px;
    cursor: pointer;
`

export default UserPage;
