
const OKSongIDs = [
    "042Sl6Mn83JHyLEqdK7uI0",
    "4SykS8RWkF6eK6xeua7XLP",
]

export const globalState = {
    loadingPageSongId: "042Sl6Mn83JHyLEqdK7uI0",
    randomMomentSongId: () => {
        return OKSongIDs[Math.floor(Math.random() * OKSongIDs.length)];
    },
    userPageSongId: "042Sl6Mn83JHyLEqdK7uI0",
    songIdForMoment: "042Sl6Mn83JHyLEqdK7uI0"
}
