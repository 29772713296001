import { useHistory } from "react-router";
import styled from "styled-components";

export const BigHeader = styled.h1`
    font-size: 32px;
    letter-spacing: -0.5px;
    font-family: ${props => props.theme.headerFont};
    color: ${props => props.theme.textColor};

    &,
    a {
        color: ${props => props.theme.textColor};
        text-decoration: none;
    }
`

export const SubHeader = styled.h3`
    font-size: 16px;
    line-height: 18px;
    font-family: ${props => props.theme.textFont};
    width: 275px;
    text-align: center;
    color: ${props => props.theme.textColor};
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
`

export const Paragraph = styled.p`
    font-size: 16px;
    line-height: 18px;
    font-family: ${props => props.theme.textFont};
    letter-spacing: -0.5px;
`

interface TopLogoProps {
    noBack?: boolean;
    goBack?: () => void;
}

export const TopLogo: React.FC<TopLogoProps> = ({ noBack, goBack }) => {
    const history = useHistory();

    return (
        <Container>
            {!noBack && (
                <BackButton
                    onClick={(e) => {
                        e.preventDefault();
                        goBack ? goBack() : history.goBack();
                    }}
                />
            )}
            <TopLogoImage onClick={() => history.push("/user/user1")} />
        </Container>
    )
}

const Container = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0 15px;
    margin-bottom: 30px;
    width: 100vw;
    position: relative;
`

const BackButton = styled.div`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 8px;
    left: 0;
    background: url(${require("../assets/chevron-left@2x.png")}) no-repeat center;
    background-size: 11px 19px;
    cursor: pointer;
`

const TopLogoImage = styled.img.attrs({
    src: require("../assets/logo-small.png"),
})`
    margin-left: auto;
    width: 44px;
`

export const Divider = styled.hr`
    width: 200px;
    height: 1px;
    /* background-color: ${props => props.theme.dividerColor}; */
    padding: 20px 0;
`

export const SmallButton = styled.button`
    border: 3px solid white;
    color: ${props => props.theme.textColor};
    color: ${props => props.theme.backgroundColor};
    background: ${props => props.theme.textColor};
    border-radius: 25px;
    padding: 5px 20px;
    min-width: 130px;
    font-family: ${props => props.theme.textFont};
    letter-spacing: -0.5px;
    font-size: 14px;
    animation: pulse 2s infinite;

    @keyframes pulse {
        0% {
            transform: scale(0.98);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }

        100% {
            transform: scale(0.98);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
    }
`

export const BigButton = styled.button`
    border: 3px solid white;
    color: ${props => props.theme.textColor};
    color: ${props => props.theme.backgroundColor};
    background: ${props => props.theme.textColor};
    border-radius: 25px;
    padding: 15px 30px;
    font-family: ${props => props.theme.textFont};
    letter-spacing: -0.5px;
    font-size: 22px;
    animation: pulse2 2s infinite;

    @keyframes pulse2 {
        0% {
            transform: scale(0.98);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }

        100% {
            transform: scale(0.98);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
    }
`


export const SongNameHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.5px;
    font-family: ${props => props.theme.headerFont};
    color: ${props => props.theme.textColor};
`

export const ArtistHeader = styled.div`
    font-size: 22px;
    font-weight: regular;
    letter-spacing: -0.5px;
    font-family: ${props => props.theme.headerFont};
    color: ${props => props.theme.textColor};
`
