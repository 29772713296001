import SpotifyWebApi from 'spotify-web-api-js';
import Song from '../models/Song';
// import { Config, SpotifyAuth } from '@awesome-cordova-plugins/spotify-auth';

let cache: any = {};
try {
    cache = JSON.parse(localStorage.getItem("cachedTracks") || '{}');
} catch (e) {}

interface Config {
    clientId: string;
    clientSecret: string;
    redirectUrl: string;
    scopes: string[];
    serviceConfiguration: {
        authorizationEndpoint: string;
        tokenEndpoint: string;
    }
}

interface Token {
    accessToken: string;
    tokenType: string;
    expiresIn: number;
}

type EntityType = ('album' | 'artist' | 'playlist' | 'track');

export interface SpotifyTrack {
    artists: string[];
    imageUrl: string;
    duration: number;
    preview: string;
    title: string;
    id: string;
    song: Song;
}

const config: Config = {
    clientId: '4cac2ebdf1744b718a1d4ec54a515472',
    clientSecret: '1463bab39e8e44e88e2541f9b9e47f8a',
    // redirectUrl: 'com.lala.memories://oauthredirect',
    redirectUrl: window.location.origin + '/home',
    scopes: [
        'streaming',
    ],
    // tokenExchangeUrl: 'http://localhost:8200/exchange',
    // tokenRefreshUrl: 'http://localhost:8200/refresh',
    serviceConfiguration: {
        authorizationEndpoint: 'https://accounts.spotify.com/authorize',
        tokenEndpoint: 'https://accounts.spotify.com/api/token',
    },

};

const toForm = (values: any) => Object.entries(values).map(([key, value]) => key + '=' + value).join('&');

const utf8Tobas64 = (value: string) => btoa(value);

export class SpotifyClient {
    private spotifyAuth: SpotifyWebApi.SpotifyWebApiJs;

    constructor() {
        this.spotifyAuth = new SpotifyWebApi();
    }

    async token(): Promise<Token> {
        return fetch(config.serviceConfiguration.tokenEndpoint, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + utf8Tobas64(config.clientId + ':' + config.clientSecret),
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: toForm({
                grant_type: 'client_credentials'
            })
        })
            .then((response) => {
                console.log('Got token response', response);
                if (response.ok) {
                    console.log('Great success!');
                    return response.json();
                }
                return Promise.reject('Failed getting token. Code: ' + response.status + ' message: ' + response.statusText);
            })
            .then((tokenJson) => ({
                accessToken: tokenJson.access_token,
                tokenType: tokenJson.token_type,
                expiresIn: tokenJson.expires_in,
            }));
    }

    track = (id: string): Promise<SpotifyTrack> => {
        if (cache[id]) {
            console.log("fetching track (cache)", id);
            return cache[id];
        }
        console.log("fetching track", id);
        return this.spotifyAuth.getTrack(id, { market: 'SE' })
            .then((a) => {
                const track = resToSpotifyTrack(a)
                cache[id] = track;
                localStorage.setItem("cachedTracks", JSON.stringify(cache));
                return track;
            });
    }

    playlistTracks = (id: string): Promise<SpotifyTrack[]> => {
        console.log('Getting playlist tracks for', id);
        return this.spotifyAuth.getPlaylist(id, {market: 'SE'}).then((response) => {
            console.log('Got playlist', response);
            return Promise.all(response.tracks.items.map((item) => item.track)
                // .filter((track) => track.is_playable)
                .map((track) => track.id)
                .map(this.track));
        })
            .catch((err) => {
                console.log('Failed feting plalist tracks for', id, err);
                throw err;
            });
    }

    login = (): Promise<void> => {
        return this.token()
            .then((token) => {
                console.log(`Got an access token, its ${token.accessToken}!`);
                console.log(`Its going to expire in ${token.expiresIn}s.`);
                this.spotifyAuth.setAccessToken(token.accessToken);
            })
            .catch((err) => {
                console.error('Failed autneticating with spotify', err);
            });
    }

    searchPlaylists = (query: string): Promise<string[]> => {
        return this.spotifyAuth.search(query, ['playlist'], { market: 'SE' })
            .then((response) => {
                console.log('Got search response', response);

                return (response.playlists?.items || []).map((playlist) => playlist.id);
            })
            .catch((err) => {
                console.error('Failed saerching for', query, err);
                return [];
            });
    }

    searchTracks = (query: string): Promise<SpotifyTrack[]> => {
        return this.spotifyAuth.search(query, ['track'], { market: 'SE' })
            .then((response) => {
                console.log('Got search response', response);
                return (response.tracks?.items || [])
                    // .filter((track) => track.is_playable)
                    // .filter((track) => track.preview_url)
                    .map((track) => resToSpotifyTrack(track));
            })
            .catch((err) => {
                console.error('Failed saerching for', query, err);
                return [];
            });
    }
}

function resToSpotifyTrack(track: SpotifyApi.SingleTrackResponse): SpotifyTrack {
    return {
        artists: track.artists.map((artist) => artist.name),
        imageUrl: track.album.images[0].url,
        duration: track.duration_ms / 60,
        preview: track.preview_url,
        title: track.name,
        id: track.id,
        song: {
            id: track.id,
            name: track.name,
            previewUrl: track.preview_url,
            album: {
                id: track.album.id,
                name: track.album.name,
                artist: {
                    id: track.artists[0].id,
                    name: track.artists[0].name,
                },
                coverImage: track.album.images[0].url,
            },
            artist: {
                id: track.artists[0].id,
                name: track.artists[0].name,
            },
            momentsCapacity: 1000,
            momentsLeft: 331,
        }
    }
}
